import Web3 from "web3";

import ValidatorContractJson from '../../data/contracts/abis/ValidatorContract.json';
import UserContractJson from '../../data/contracts/abis/UserContract.json';
import SupporterContractJson from '../../data/contracts/abis/SupporterContract.json';
import ResearcherPoolContractJson from '../../data/contracts/abis/ResearcherPool.json';
import ResearcherContractJson from '../../data/contracts/abis/ResearcherContract.json';
import RcTokenIcoContractJson from '../../data/contracts/abis/RcTokenIco.json';
import RcTokenContractJson from '../../data/contracts/abis/RcToken.json';
import ProducerPoolContractJson from '../../data/contracts/abis/ProducerPool.json';
import InspectorPoolContractJson from '../../data/contracts/abis/InspectorPool.json';
import DevelopersPoolContractJson from '../../data/contracts/abis/DeveloperPool.json';
import ActivistContractJson from '../../data/contracts/abis/ActivistContract.json';
import SintropContractJson from '../../data/contracts/abis/Sintrop.json';
import ProducerContractJson from '../../data/contracts/abis/ProducerContract.json';
import InspectorContractJson from '../../data/contracts/abis/InspectorContract.json';
import DeveloperContractJson from '../../data/contracts/abis/DeveloperContract.json';
import ValidatorPoolContractJson from '../../data/contracts/abis/ValidatorPool.json';
import CategoryContractJson from '../../data/contracts/abis/CategoryContract.json';
import InvitationContractJson from '../../data/contracts/abis/InvitationContract.json';
import ContributorContractJson from '../../data/contracts/abis/ContributorContract.json';

export const validatorContractAddress = '0xaD2a48F19BC84eddD27f18De9f261635016493E8';
export const validatorPoolContractAddress = '0x71715cFb10FC1C78A2928C11d20a8E192430828D';
export const userContractAddress = '0x069B0CCe92F5c6F5bb43DEb735aDB688069885cC';
export const supporterContractAddress = '0x2d3BF756eDa01a1fAFae55AbDFA8fe2A95Fb8A78';
export const supporterPoolAddress = '0x1Fc4638cf47f851FF8fc0C9bC16648BA92A03994';
export const researcherPoolContractAddress = '0x2c855936FBdF229CE57c36b35d4E0dc7928ED687';
export const researcherContractAddress = '0xE81eB5222cd5051268ECA2dD4184EbA1977372E6';
export const rcTokenIconAddress = '0x1e1cc60a91380c81ecabfbd497c72a7f134f39af';
export const RcTokenContractAddress = '0x472e9e275c0948ABd31E36645f1e21E25bc4158a';
export const producerPoolContractAddress = '0x68e67708aA8F226b0E385434EfDfBcDEDa7644d9';
export const producerContractAddress = '0x783be4f2C0b4dFD666de91122C9A964C47F77537';
export const inspectorPoolContractAddress = '0x80d26C016B8845C0646c64Cfb02Cb27Ee633851f';
export const inspectorContractAddress = '0x417996E36c0C320E93bBE2942b375D93cE741694';
export const developersPoolContractAddress = '0xb8A1d39C5827D3729f667fCDdE1ECd2B6A904D31';
export const developerContractAddress = '0x6594b25A99ed3d2c306aBD38834eB8Ea787506bE';
export const activistContractAddress = '0x3a45e588194e7E12921a12d513912A33BA63a9e3';
export const activistPoolContractAddress = '0x739B42B5319F4BcA23CBF06C1a1de05B8B50E7Cf';
export const sintropContractAddress = '0xf5571E56f573beE4a0c08cc362105aca62dcFbd1';
export const categoryContractAddress = '0x71caDa4d72583a5287EcAE9Ca21448154df9a1B3';
export const invitationContractAddress = '0x3bE63EE09AF3B56a23d81F730720A8C8597eC8C5';
export const contributorContractAddress = '0xF23dd8A9692994F58F6bcC4C125F3E5e7631d29a';
export const contributorPoolContractAddress = '0xA56E0368207E5F3d07200Ca7C0A8A7954958c9b5';

const provider = window.ethereum ? window.ethereum : process.env.REACT_APP_CHAIN_RPC;
export const web3 = new Web3(provider);

export const ValidatorContract = new web3.eth.Contract(ValidatorContractJson, validatorContractAddress);
export const UserContract = new web3.eth.Contract(UserContractJson, userContractAddress);
export const SupporterContract = new web3.eth.Contract(SupporterContractJson, supporterContractAddress);
export const ResearcherPoolContract = new web3.eth.Contract(ResearcherPoolContractJson, researcherPoolContractAddress);
export const ResearcherContract = new web3.eth.Contract(ResearcherContractJson, researcherContractAddress);
export const RcTokenIcoContract = new web3.eth.Contract(RcTokenIcoContractJson, rcTokenIconAddress);
export const RcTokenContract = new web3.eth.Contract(RcTokenContractJson, RcTokenContractAddress);
export const ProducerPoolContract = new web3.eth.Contract(ProducerPoolContractJson, producerPoolContractAddress);
export const InspectorPoolContract = new web3.eth.Contract(InspectorPoolContractJson, inspectorPoolContractAddress);
export const DevelopersPoolContract = new web3.eth.Contract(DevelopersPoolContractJson, developersPoolContractAddress);
export const ActivistContract = new web3.eth.Contract(ActivistContractJson, activistContractAddress);
export const SintropContract = new web3.eth.Contract(SintropContractJson, sintropContractAddress);
export const ProducerContract = new web3.eth.Contract(ProducerContractJson, producerContractAddress);
export const InspectorContract = new web3.eth.Contract(InspectorContractJson, inspectorContractAddress);
export const DeveloperContract = new web3.eth.Contract(DeveloperContractJson, developerContractAddress);
export const ValidatorPoolContract = new web3.eth.Contract(ValidatorPoolContractJson, validatorPoolContractAddress);
export const CategoryContract = new web3.eth.Contract(CategoryContractJson, categoryContractAddress);
export const InvitationContract = new web3.eth.Contract(InvitationContractJson, invitationContractAddress);
export const ContributorContract = new web3.eth.Contract(ContributorContractJson, contributorContractAddress);